.noUi-target {
    margin-top: 15px;
}

.noUi-horizontal {
    height: 9px !important;
}

.noUi-horizontal .noUi-handle {
    height: 10px !important;
    width: 20px;
        top: -2px !important;
    background-color: #828EA1;
    box-shadow: none;
}

.noUi-tooltip {
    top: -7px;
    height: 23px;
    font-size: 12px;
    color: white !important;
    background-color: #828EA1 !important;
    padding: 2px 10px !important;
}

.slider-container {
    margin-left: 35px;
    width: 100%;
    font-size: 12px;
    max-width: 650px;
}

.slider-container-edit {
    width: 100%;
    font-size: 12px;
    max-width: 650px;
}

.noUi-handle:before, .noUi-handle:after {
    display: none !important;
}


html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    left: -17px !important;
    right: 0 !important;
}