.subtitle {
    color: #828EA1;
    font-family: Helvetica;
    font-size: 18px;
    line-height: 22px;
}

.agent-text {
    margin: 1px;
}

.agent-list {
    border-bottom: 1px solid lightgrey;
    margin: 10px 0;
    padding: 10px;
}

.agent-full-name {
    font-size: 1.2em;
    font-weight: 600;
}