.summary {
    margin: 0 auto;
    width: 1024px;
}

.summary-container {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 50px;
}

.plan-create-message {
    color: #828EA1;
    font-size: 20px;
    max-width: 634px;
}

.plan-create-title {
    color: #828EA1;
    font-size: 32px;
    margin-top: 15px;
}

.plan-create-light {
    font-weight: 100;

}

.summary .create-btn {
    color: #828EA1;
    letter-spacing: 0px;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    border: 2px solid #828EA1;
    background-color: white;
    height: 40px;
    border-radius: 3px;
    margin-right: 23px;
}


.summary .create-btn i {
    transform: translate(-8px, 4px);
}

.summary .plan-headers {
    width: 977px;
    background-color: #e3e7ed;
    display: flex;
    flex-wrap: wrap;

}

.summary .plan-btn {
    color: #677488;
    font-family: "Helvetica Neue";
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    padding: 20px 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.summary .selected {
    background-color: #ccd2dd;
    color: #677488;
}

.space-right {
    margin-right: 25px;
}