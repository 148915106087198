.header-container {
    height: 45px;
    padding-top: 10px;
    margin: 0 auto;
    width: 1024px;
    background-color: #F6F8FC;
    box-shadow: 0 1px 4px 3px rgba(103,116,136,0.09);
}

.global-title {
   margin: 0px 0 0 20px;
   cursor: pointer;
}
