
.simple-select {
    /* display: flex;
    justify-content: space-between; */
    width: 45%;

}

.simple-container {
    width: 80%;
    margin-left: 5%;
}

.simple-spacing {
    margin-bottom: 10px;
}

.simple-spacing .switch-ctn {
    margin-top: 12px;

}


