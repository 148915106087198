.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.optimus-title {
  color: #4B5668;
  font-size: 25px;
  position: relative;
  width: 150px;
  margin-bottom: 0px;
  text-align: center;
}

.optimus-title-container {
  display: flex;
  justify-content: center;
}

.rectangle-top {	
  height: 8.82px;	
  width: 8.82px;		
  border: 2px solid #828EA1;	
  border-radius: 2px;
  position: absolute;
  top: 8px;
  left: 2px;

}

.rectangle-bottom {	
  height: 8.82px;	
  width: 8.82px;		
  border: 2px solid #828EA1;	
  border-radius: 2px;
  position: absolute;
  top: 14.82px;
  left: 8.82px;

}

.nav-button-text {
  color: #E7EDF6;
  letter-spacing: 0px;
font-family: "Helvetica Neue";
font-size: 14px;
font-weight: 500;
line-height: 17px;
  text-align: center;
  background-color: #828EA1;
  height: 40px;	
  float: right;
  border-radius: 3px;
  width: 135px;
}

.nav-button-text:hover {
  background-color: rgb(166, 177, 194);
}

.nav-button-text:focus {
  background-color: rgb(106, 112, 122);
}