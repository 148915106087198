.segmentation-table {
    padding-bottom: 10px;
}

.segmentation-table table {
    width: 100%;
    border-collapse:collapse;
}

.table-header {
    background-color: #A4b0c3;
    color: white;
}

.table-header > th {
    height: 25px;
    padding: 0;
    border: 1px solid #a4b0c3;
    text-align: center;
    border-radius: 0;
    padding: 2px 5px 2px 15px;
}

.table-body-row {
    text-align: center;
}

.segmentation-table thead td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    background-color: #E7EDF6;
    text-align: center;
    padding: 2px 5px 2px 15px;
    border: 2px solid whitesmoke;
}

.segmentation-table tbody td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    background-color: #FFFFFF;
    text-align: center;
    padding: 2px 5px 2px 15px;
    border: 2px solid whitesmoke;
}


