.plan-viewer {
    margin: 0 auto;
    width: 1024px;
}




.plan-viewer-container {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 50px;
}

.plan-create-message {
    color: #828EA1;
    font-size: 20px;
    max-width: 634px;
}

.plan-create-title {
    color: #828EA1;
    font-size: 32px;
    margin-top: 15px;
}

.plan-create-light {
    font-weight: 100;

}

.plan-viewer .create-btn {
    color: white;
    letter-spacing: 0px;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    border: 2px solid #828EA1;
    background-color: #7591A7;
    height: 40px;
    border-radius: 3px;
    margin-bottom: 15px;
}

.plan-viewer .create-btn i {
    transform: translate(-8px, 4px);
}

.space-right {
    margin-right: 25px;
}