.market-overview {
    margin: 0 auto;
    width: 1024px;
}

.market-overview .subtitle {

        color: #828EA1;
        font-family: Helvetica;
        font-size: 18px;
        line-height: 22px;
}

.market-overview-container {
    padding-left: 25px;
    padding-right: 25px;
}

.market-overview-message {
    color: #828EA1;
    font-size: 20px;
    max-width: 634px;
    margin-top: 5px;
}

.market-overview-title {
    color: #828EA1;
    font-size: 32px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.market-overview-light {
    font-weight: 100;

}

.market-overview .over-view-chart-container {
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
}

.market-overview .competitor-container {
    padding-left: 25px;
    padding-right: 25px;
}

.space-right {
    margin-right: 25px;
}

.end {
    padding-bottom: 40px;
}

.growth-header {
    color: #828EA1;
    font-family: Helvetica;
    font-size: .8em;
    font-style: italic;
}