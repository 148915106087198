.plan-detail-labels {
    color: #828EA1;	
    font-family: "Helvetica Neue";	
    font-size: 11px;	
    font-weight: 500;	
    line-height: 12px;	
    text-align: center;
    display: block;
}

.plan-detail-values {	
    color: #828EA1;	
    font-family: Helvetica;	
    font-size: 30px;	
    font-weight: bold;	
    line-height: 18px;	
    text-align: center;
    display: block;
    padding-top: 5px;
    padding-bottom: 10px;
}

.row-edits {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    margin-bottom: 0;
}

.row-edits .col {
    flex-grow: 1;
    max-width: 33%;
}

.plan-detail-container {
    justify-content: space-between;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(226px - 33px);
}