.market-headline h1 {
        height: 66px;
        width: 153px;
        color: #4B5668;
        font-family: Helvetica;
        font-size: 55px;
        font-weight: bold;
        line-height: 66px;
        margin-bottom: 5px;
        margin-top: 10px;
}

.market-headline p {
	height: 32px;
	/* width: 169px; */
	color: #4B5668;
	font-family: "Helvetica Neue";
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
    text-align: center;
    margin-top: 0;
    width: 100%;
}

.market-headline {
    padding-left: 25px;
    padding-right: 25px;

}

.market-headline section {
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.market-summary {
    flex-grow: 1;
    width: 100%;
}

.market-summary > h1 {
    width: 100%;
}