.plan-card {
	min-height: 257px;
	width: 977px;
	border: 0.5px solid #D8D8D8;
	background-color: #FFFFFF;
	box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.24);
	margin: 0 0 15px 0;
	position: relative;
}

.plan-card-title {
	color: #828EA1;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 0;
	margin-bottom: 5px;
}

.plan-card-right {
	padding: 0px 10px;
	margin: 10px 0px;
	border-right: 0.5px solid #A4B0C3;

}

.rationale {
	color: #828EA1;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 5px;
    padding: 0;
}

.plan-card-left > .row {
	margin: 0;
}

.plan-card-left .details {
	width: 100% !important;
    text-align: left !important;
    padding-left: 20px !important;
}

.plan-card-left > .row > .tab-items {	
	color: #677488;
    font-family: "Helvetica Neue";
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
    padding: 10px 0px 5px 0px;
	text-align: center;
	cursor: pointer;
}

.tab-item-active {
    border-bottom: 2px solid #677488;
}

.rationale-item {
	color: #677488;	
	font-size: 14px;	
	line-height: 16px;
	margin-top: 5px;
}

.delete-row {
	cursor: pointer;
	position: absolute;
    right: 40px;
    bottom: 5px;
}

.delete-row p {
	color: #677488;	
	margin: 0;
}

.dlt-icon {
	color: #677488;	
	position: absolute;
    right: 45px;
    top: 4px;
}

.hide-plan {
	display: none;
}