.cap-container {
    /* width: 80%;
    margin-left: 5%; */
    display: flex;
    justify-content: space-between;
}

.cap-spacing {
    margin-bottom: 10px;
}

.cap-spacing .switch {
    padding-top: 12px;
}

.cap-select {
    width: 45%;
}