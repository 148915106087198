
.franchise-select label {
    /* display: flex; */
    padding-left: 40px;
    
}

.franchise-select {
    position: absolute;
    top: -42px;
    right: 296px;
}

.franchise-container {
    width: 80%;
    margin-left: 5%;
}

.franchise-select  .switch {
    padding-top: 29px;
}