.market-select {
    max-width: 533px;
    margin: 65px auto;
    text-align: center;
}



.market-select-message {
    color: #828EA1;
    font-size: 20px;
}

.market-select-title {
    color: #828EA1;
    font-size: 32px;
    margin-top: 5px;
    font-weight: 100;
}



.select-rectangle {
    height: 53px;	
    width: 424px;	
    border: 2px solid #A4B0C3;	
    opacity: 0.6;	
    display: block;
    margin: auto;
    background-color: rgba(255,255,255,0.64);
}

.nav-continue {
    margin-top: 25px;
    color: #E7EDF6;
    letter-spacing: 0px;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    background-color: #828EA1;
    height: 40px;	
    border-radius: 3px;
    width: 135px;
}

.nav-continue:hover {
    background-color: rgb(166, 177, 194);
}
  
.nav-continue:focus {
    background-color: rgb(106, 112, 122);
}