.segment-container {
    display: flex;
    margin-top: 3px;
    }
    
    .segment-label {
    color: #828EA1;
    font-family: "Helvetica Neue";
    font-size: 14px;
    line-height: 16px;
    }
    
    .segment-item {
    height: 40px;
    width: 49px;
    color: #677488;
    font-family: "Helvetica Neue";
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #828EA1;
    background-color: #FFFFFF;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.25);
    }
    
    .segment-item.selected {
        background-color: #84c7c1;
        color: white;
    }

    .segment-item:focus {
        background-color: #FFFFFF;
    }

    .segment-item.selected:focus {
        background-color: #84c7c1;
        color: white;
    }