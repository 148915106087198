.optimality {
	width: 977px;
    background-color:#FBFAFA;
    padding: 25px;
}

.optimality-segment {
    margin-top: 20px;
}

.optimality .title {
        color: #828EA1;
        font-family: Helvetica;
        font-size: 18px;
        line-height: 22px;
        margin-top: 0;
}

.optimality-table {
    padding-bottom: 10px;
    width: 100%;
}

.optimality-table > h4 {
    color: #677488;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
}

.optimality-table table {
    width: 100%;
    border-collapse:collapse;
    font-size: 14px;
    table-layout: fixed;
    
}

.optimality-table .table-header {
    background-color: #A4b0c3;
    color: white;
}

.optimality-table .table-header > th {
    height: 25px;
    padding: 0;
    border: 1px solid #a4b0c3;
    text-align: center;
    border-radius: 0;
}

.optimality-table .table-body-row {
    text-align: center;
}

.optimality-table .table-body-row > td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    text-align: right;
    height: 50px;
}

.optimality-table .table-body-row > td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    text-align: center;
}

.optimality-table .table-body-row > td:first-of-type {
    padding-left: 8px;
    text-align: left;
}


.optimality-table .recruiting-target {
    width: 100%;
    padding: 15px 25px 15px 25px;
    font-size: 12px;
    max-width: 650px;
}

.optimality-table .noUi-target {
    margin-top: 0;
}

.seg-button-text {
    color: #E7EDF6;
    letter-spacing: 0px;
    font-size: 20px;
    font-weight: 500;
    padding: 0px 10px;
    margin: 0 25px;
    text-align: center;
    background-color: #828EA1;
    border-radius: 3px;
  }
  
  .seg-button-text:hover {
    background-color: rgb(166, 177, 194);
  }
  
  .seg-button-text:focus {
    background-color: rgb(106, 112, 122);
  }

.optimality-message {
    margin: 20px;
    font-size: 14px;
    color: #828EA1;
}