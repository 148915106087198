.tier-current {
    height: 34.03px;
    width: 43px;
    border: 1px solid #828EA1;
    padding-top: 4px;
    text-align: center;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.25);
}

.tier-message {
color: #828EA1;
font-family: "Helvetica Neue";
font-size: 11px;
line-height: 15px;
margin-top: 7px;
}

.tier-container {
    margin-left: 25px;
    }
    
.tier-button {
height: 34.03px;
background-color: #828EA1;
padding: 10px 10px 4px 10px;
border-radius: 0;
box-shadow: 0 4px 5px 0 rgba(0,0,0,0.25);
color: #E7EDF6;
letter-spacing: 0px;
font-family: "Helvetica Neue";
font-size: 14px;
line-height: 17px;
text-align: center;

}
.tier-button:hover {
    background-color: rgb(166, 177, 194);
    }
    
 .tier-button:focus {
    background-color: rgb(106, 112, 122);
    }