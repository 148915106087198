.plan-creator {
        width: 975px;
        border: 1px solid rgba(164,176,195,0.3);
        background-color: #FCFDFE;
        box-shadow: 0 2px 4px 0 rgba(75,86,104,0.29);
        margin: 15px 0;
        transition: transform linear 200ms;
}

.creator-mover {
    transform: translate(0px, 500px);
}

.plan-creator-text {
    height: 19px;
    color: #828EA1;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    width: 100%;
    padding-top: 40px;
    text-align: center;
    cursor: pointer;
}

.plan-creator-title {
        color: #828EA1;
        font-family: Helvetica;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
        margin-top: 0;
}

.pc-top {
    padding: 35px 35px 3px 35px;
    border-bottom: 1px solid rgba(103,116,136,0.46);
    position: relative;
}

.plan-creator .pc-top > .set-name{
    width: 300px;
}

.pc-mid {
    margin: 0 35px;
    padding: 21px 0px 10px 0;
    border-bottom: 0.5px solid #A4B0C3;
}

.pc-above-bottom {
    margin: 0 35px;
    padding: 21px 0px 10px 0;
    /* border-bottom: 0.5px solid #A4B0C3; */
}

.pc-bottom {
    margin: 0 35px;
    padding: 21px 0px 10px 0;
}

.pc-text-message {
    height: 18px;
    width: 455px;
    color: #828EA1;
    font-family: "Helvetica Neue";
    font-size: 16px;
    line-height: 18px;
}

.flex-row {
    display: flex;
}

.close-icon {
  color:  #828EA1;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 100;
}


.switch-label {
        color: #828EA1;
        font-family: "Helvetica Neue";
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: right;
}

.plan-creator .create-button-text {
    color: #E7EDF6;
    letter-spacing: 0px;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    background-color: #7591A7;
    height: 40px;	
    border-radius: 3px;
    float: right;
    margin: 10px 0px;
    }
    
    .plan-creator .create-button-text:hover {
    background-color: rgb(166, 177, 194);
    }
    
    .plan-creator .create-button-text:focus {
    background-color: rgb(106, 112, 122);
    }
    

