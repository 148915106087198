body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.optimus-title {
  color: #4B5668;
  font-size: 25px;
  position: relative;
  width: 150px;
  margin-bottom: 0px;
  text-align: center;
}

.optimus-title-container {
  display: flex;
  justify-content: center;
}

.rectangle-top {	
  height: 8.82px;	
  width: 8.82px;		
  border: 2px solid #828EA1;	
  border-radius: 2px;
  position: absolute;
  top: 8px;
  left: 2px;

}

.rectangle-bottom {	
  height: 8.82px;	
  width: 8.82px;		
  border: 2px solid #828EA1;	
  border-radius: 2px;
  position: absolute;
  top: 14.82px;
  left: 8.82px;

}

.nav-button-text {
  color: #E7EDF6;
  letter-spacing: 0px;
font-family: "Helvetica Neue";
font-size: 14px;
font-weight: 500;
line-height: 17px;
  text-align: center;
  background-color: #828EA1;
  height: 40px;	
  float: right;
  border-radius: 3px;
  width: 135px;
}

.nav-button-text:hover {
  background-color: rgb(166, 177, 194);
}

.nav-button-text:focus {
  background-color: rgb(106, 112, 122);
}
.market-select {
    max-width: 533px;
    margin: 65px auto;
    text-align: center;
}



.market-select-message {
    color: #828EA1;
    font-size: 20px;
}

.market-select-title {
    color: #828EA1;
    font-size: 32px;
    margin-top: 5px;
    font-weight: 100;
}



.select-rectangle {
    height: 53px;	
    width: 424px;	
    border: 2px solid #A4B0C3;	
    opacity: 0.6;	
    display: block;
    margin: auto;
    background-color: rgba(255,255,255,0.64);
}

.nav-continue {
    margin-top: 25px;
    color: #E7EDF6;
    letter-spacing: 0px;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    background-color: #828EA1;
    height: 40px;	
    border-radius: 3px;
    width: 135px;
}

.nav-continue:hover {
    background-color: rgb(166, 177, 194);
}
  
.nav-continue:focus {
    background-color: rgb(106, 112, 122);
}
.market-overview {
    margin: 0 auto;
    width: 1024px;
}

.market-overview .subtitle {

        color: #828EA1;
        font-family: Helvetica;
        font-size: 18px;
        line-height: 22px;
}

.market-overview-container {
    padding-left: 25px;
    padding-right: 25px;
}

.market-overview-message {
    color: #828EA1;
    font-size: 20px;
    max-width: 634px;
    margin-top: 5px;
}

.market-overview-title {
    color: #828EA1;
    font-size: 32px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.market-overview-light {
    font-weight: 100;

}

.market-overview .over-view-chart-container {
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
}

.market-overview .competitor-container {
    padding-left: 25px;
    padding-right: 25px;
}

.space-right {
    margin-right: 25px;
}

.end {
    padding-bottom: 40px;
}

.growth-header {
    color: #828EA1;
    font-family: Helvetica;
    font-size: .8em;
    font-style: italic;
}
.market-headline h1 {
        height: 66px;
        width: 153px;
        color: #4B5668;
        font-family: Helvetica;
        font-size: 55px;
        font-weight: bold;
        line-height: 66px;
        margin-bottom: 5px;
        margin-top: 10px;
}

.market-headline p {
	height: 32px;
	/* width: 169px; */
	color: #4B5668;
	font-family: "Helvetica Neue";
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
    text-align: center;
    margin-top: 0;
    width: 100%;
}

.market-headline {
    padding-left: 25px;
    padding-right: 25px;

}

.market-headline section {
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.market-summary {
    flex-grow: 1;
    width: 100%;
}

.market-summary > h1 {
    width: 100%;
}



.competitor-table {
    padding-bottom: 10px;
}

.competitor-table table {
    width: 100%;
    border-collapse:collapse;
}

.table-header {
    background-color: #A4b0c3;
    color: white;
}

.table-header > th {
    height: 25px;
    padding: 0;
    border: 1px solid #a4b0c3;
    text-align: center;
    border-radius: 0;
    padding: 2px 5px 2px 15px;
}

.table-body-row {
    text-align: center;
}

.competitor-table  td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    text-align: right;
    padding: 2px 5px 2px 15px;
}

.highlight {
    background-color: rgba(230, 235, 224, 0.67);
}
.segmentation-table {
    padding-bottom: 10px;
}

.segmentation-table table {
    width: 100%;
    border-collapse:collapse;
}

.table-header {
    background-color: #A4b0c3;
    color: white;
}

.table-header > th {
    height: 25px;
    padding: 0;
    border: 1px solid #a4b0c3;
    text-align: center;
    border-radius: 0;
    padding: 2px 5px 2px 15px;
}

.table-body-row {
    text-align: center;
}

.segmentation-table thead td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    background-color: #E7EDF6;
    text-align: center;
    padding: 2px 5px 2px 15px;
    border: 2px solid whitesmoke;
}

.segmentation-table tbody td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    background-color: #FFFFFF;
    text-align: center;
    padding: 2px 5px 2px 15px;
    border: 2px solid whitesmoke;
}



.plan-viewer {
    margin: 0 auto;
    width: 1024px;
}




.plan-viewer-container {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 50px;
}

.plan-create-message {
    color: #828EA1;
    font-size: 20px;
    max-width: 634px;
}

.plan-create-title {
    color: #828EA1;
    font-size: 32px;
    margin-top: 15px;
}

.plan-create-light {
    font-weight: 100;

}

.plan-viewer .create-btn {
    color: white;
    letter-spacing: 0px;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    border: 2px solid #828EA1;
    background-color: #7591A7;
    height: 40px;
    border-radius: 3px;
    margin-bottom: 15px;
}

.plan-viewer .create-btn i {
    -webkit-transform: translate(-8px, 4px);
            transform: translate(-8px, 4px);
}

.space-right {
    margin-right: 25px;
}
.plan-card {
	min-height: 257px;
	width: 977px;
	border: 0.5px solid #D8D8D8;
	background-color: #FFFFFF;
	box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.24);
	margin: 0 0 15px 0;
	position: relative;
}

.plan-card-title {
	color: #828EA1;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 0;
	margin-bottom: 5px;
}

.plan-card-right {
	padding: 0px 10px;
	margin: 10px 0px;
	border-right: 0.5px solid #A4B0C3;

}

.rationale {
	color: #828EA1;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 5px;
    padding: 0;
}

.plan-card-left > .row {
	margin: 0;
}

.plan-card-left .details {
	width: 100% !important;
    text-align: left !important;
    padding-left: 20px !important;
}

.plan-card-left > .row > .tab-items {	
	color: #677488;
    font-family: "Helvetica Neue";
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
    padding: 10px 0px 5px 0px;
	text-align: center;
	cursor: pointer;
}

.tab-item-active {
    border-bottom: 2px solid #677488;
}

.rationale-item {
	color: #677488;	
	font-size: 14px;	
	line-height: 16px;
	margin-top: 5px;
}

.delete-row {
	cursor: pointer;
	position: absolute;
    right: 40px;
    bottom: 5px;
}

.delete-row p {
	color: #677488;	
	margin: 0;
}

.dlt-icon {
	color: #677488;	
	position: absolute;
    right: 45px;
    top: 4px;
}

.hide-plan {
	display: none;
}
.tier-table {
    padding-bottom: 10px;
}

.tier-table table {
    width: 100%;
    border-collapse:collapse;
}

.tier-table .table-header {
    background-color: #A4b0c3;
    color: white;
}

.tier-table .table-header > th {
    height: 25px;
    padding: 0;
    border: 1px solid #a4b0c3;
    text-align: center;
    border-radius: 0;
}

.tier-table .table-body-row {
    text-align: center;
}

.tier-table .table-body-row > td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    text-align: right;

}
.plan-detail-labels {
    color: #828EA1;	
    font-family: "Helvetica Neue";	
    font-size: 11px;	
    font-weight: 500;	
    line-height: 12px;	
    text-align: center;
    display: block;
}

.plan-detail-values {	
    color: #828EA1;	
    font-family: Helvetica;	
    font-size: 30px;	
    font-weight: bold;	
    line-height: 18px;	
    text-align: center;
    display: block;
    padding-top: 5px;
    padding-bottom: 10px;
}

.row-edits {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    margin-bottom: 0;
}

.row-edits .col {
    flex-grow: 1;
    max-width: 33%;
}

.plan-detail-container {
    justify-content: space-between;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(226px - 33px);
}
.plan-creator {
        width: 975px;
        border: 1px solid rgba(164,176,195,0.3);
        background-color: #FCFDFE;
        box-shadow: 0 2px 4px 0 rgba(75,86,104,0.29);
        margin: 15px 0;
        -webkit-transition: -webkit-transform linear 200ms;
        transition: -webkit-transform linear 200ms;
        transition: transform linear 200ms;
        transition: transform linear 200ms, -webkit-transform linear 200ms;
}

.creator-mover {
    -webkit-transform: translate(0px, 500px);
            transform: translate(0px, 500px);
}

.plan-creator-text {
    height: 19px;
    color: #828EA1;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    width: 100%;
    padding-top: 40px;
    text-align: center;
    cursor: pointer;
}

.plan-creator-title {
        color: #828EA1;
        font-family: Helvetica;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
        margin-top: 0;
}

.pc-top {
    padding: 35px 35px 3px 35px;
    border-bottom: 1px solid rgba(103,116,136,0.46);
    position: relative;
}

.plan-creator .pc-top > .set-name{
    width: 300px;
}

.pc-mid {
    margin: 0 35px;
    padding: 21px 0px 10px 0;
    border-bottom: 0.5px solid #A4B0C3;
}

.pc-above-bottom {
    margin: 0 35px;
    padding: 21px 0px 10px 0;
    /* border-bottom: 0.5px solid #A4B0C3; */
}

.pc-bottom {
    margin: 0 35px;
    padding: 21px 0px 10px 0;
}

.pc-text-message {
    height: 18px;
    width: 455px;
    color: #828EA1;
    font-family: "Helvetica Neue";
    font-size: 16px;
    line-height: 18px;
}

.flex-row {
    display: flex;
}

.close-icon {
  color:  #828EA1;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 100;
}


.switch-label {
        color: #828EA1;
        font-family: "Helvetica Neue";
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: right;
}

.plan-creator .create-button-text {
    color: #E7EDF6;
    letter-spacing: 0px;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    background-color: #7591A7;
    height: 40px;	
    border-radius: 3px;
    float: right;
    margin: 10px 0px;
    }
    
    .plan-creator .create-button-text:hover {
    background-color: rgb(166, 177, 194);
    }
    
    .plan-creator .create-button-text:focus {
    background-color: rgb(106, 112, 122);
    }
    


.lever {
    margin-left: 0;
    margin-right: 8px;
}

.switch-label {
    margin-right: 16px;
}
.tier-current {
    height: 34.03px;
    width: 43px;
    border: 1px solid #828EA1;
    padding-top: 4px;
    text-align: center;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.25);
}

.tier-message {
color: #828EA1;
font-family: "Helvetica Neue";
font-size: 11px;
line-height: 15px;
margin-top: 7px;
}

.tier-container {
    margin-left: 25px;
    }
    
.tier-button {
height: 34.03px;
background-color: #828EA1;
padding: 10px 10px 4px 10px;
border-radius: 0;
box-shadow: 0 4px 5px 0 rgba(0,0,0,0.25);
color: #E7EDF6;
letter-spacing: 0px;
font-family: "Helvetica Neue";
font-size: 14px;
line-height: 17px;
text-align: center;

}
.tier-button:hover {
    background-color: rgb(166, 177, 194);
    }
    
 .tier-button:focus {
    background-color: rgb(106, 112, 122);
    }
.segment-container {
    display: flex;
    margin-top: 3px;
    }
    
    .segment-label {
    color: #828EA1;
    font-family: "Helvetica Neue";
    font-size: 14px;
    line-height: 16px;
    }
    
    .segment-item {
    height: 40px;
    width: 49px;
    color: #677488;
    font-family: "Helvetica Neue";
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #828EA1;
    background-color: #FFFFFF;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.25);
    }
    
    .segment-item.selected {
        background-color: #84c7c1;
        color: white;
    }

    .segment-item:focus {
        background-color: #FFFFFF;
    }

    .segment-item.selected:focus {
        background-color: #84c7c1;
        color: white;
    }
.lever {
    margin-left: 0;
    margin-right: 8px;
}

.switch-label {
    margin-right: 16px;
}

.structure-switch {
    padding-top: 30px;
    padding-left: 33px;
}

.structure-switch .lever {
    background-color: lightblue !important;
}

.pc-mid .switch label input[type=checkbox]:checked+.lever:after {
    background-color: #FFFFFF;
}

.pc-mid .switch label .lever:after {
    background-color: #FFFFFF;

}


.pc-mid .switch label .lever:before {
    background-color: #FFFFFF;
}

.toast-message {
    color: #ffffff !important;
    background-color: #828EA1 !important;
    position: relative;
}

.fix-loader {
    -webkit-transform: scale(.4) translate(50px, -5px);
            transform: scale(.4) translate(50px, -5px);
    position: absolute;
    top: 3px;
    left: 190px;

}

.optimizing {
    margin-left: 10px;
    font-size: 20px;
}
.summary {
    margin: 0 auto;
    width: 1024px;
}

.summary-container {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 50px;
}

.plan-create-message {
    color: #828EA1;
    font-size: 20px;
    max-width: 634px;
}

.plan-create-title {
    color: #828EA1;
    font-size: 32px;
    margin-top: 15px;
}

.plan-create-light {
    font-weight: 100;

}

.summary .create-btn {
    color: #828EA1;
    letter-spacing: 0px;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    border: 2px solid #828EA1;
    background-color: white;
    height: 40px;
    border-radius: 3px;
    margin-right: 23px;
}


.summary .create-btn i {
    -webkit-transform: translate(-8px, 4px);
            transform: translate(-8px, 4px);
}

.summary .plan-headers {
    width: 977px;
    background-color: #e3e7ed;
    display: flex;
    flex-wrap: wrap;

}

.summary .plan-btn {
    color: #677488;
    font-family: "Helvetica Neue";
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    padding: 20px 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.summary .selected {
    background-color: #ccd2dd;
    color: #677488;
}

.space-right {
    margin-right: 25px;
}
.edit-plan-container {
    width: 975px;
    border: 1px solid rgba(164,176,195,0.3);
    background-color: #FCFDFE;
    box-shadow: 0 2px 4px 0 rgba(75,86,104,0.29);
}

.edit-plan-text {
height: 19px;
color: #828EA1;
font-family: "Helvetica Neue";
font-size: 16px;
font-weight: 500;
line-height: 19px;
width: 100%;
padding-top: 40px;
text-align: center;
cursor: pointer;
}

.edit-plan .pc-top > .edit-name {
    width: 300px;;
}

.edit-plan .edit-plan-title {
    color: #828EA1;
    font-family: Helvetica;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 0;
}

.edit-plan .pc-top {
padding: 35px 35px 3px 35px;
border-bottom: none;
position: relative;
}

.edit-plan .pc-mid {
margin: 0 35px;
padding: 21px 0px 10px 0;
border-bottom: 0.5px solid #A4B0C3;
}

.edit-plan .pc-bottom {
margin: 0 35px;
padding: 21px 0px 10px 0;
}

.edit-plan .pc-text-message {
    margin: 0;
height: 18px;
width: 455px;
color: #828EA1;
font-family: "Helvetica Neue";
font-size: 16px;
line-height: 18px;
}

.edit-plan .btn-container {
    display: flex;
    justify-content: center;

}

.edit-plan .edit-button-text {
color: #E7EDF6;
letter-spacing: 0px;
font-family: "Helvetica Neue";
font-size: 14px;
font-weight: 500;
line-height: 17px;
text-align: center;
background-color: #828EA1;
height: 40px;	
border-radius: 3px;

margin: 10px 0px;
}

.edit-plan .edit-button-text:hover {
background-color: rgb(166, 177, 194);
}

.edit-plan .edit-button-text:focus {
background-color: rgb(106, 112, 122);
}


.edit-plan .flex-row {
display: flex;
}


.edit-plan .close-icon {
color:  #828EA1;
position: absolute;
right: 10px;
top: 10px;
cursor: pointer;
z-index: 100;
}



.switch-label {
    color: #828EA1;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: right;
}


.edit-tier{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.edit-tier .tier-table {
    width: 100%;
    margin-right: 0;
}

.edit-tier .tier-table > table {
    table-layout: fixed;
}

.tier-sliders .simple-select {
    width: 100% !important;
}

.tier-sliders .slider-container-edit {
    max-width: 100% !important;
}

.edit-tier > div {
    display: flex;
    margin-top: 20px;
    margin-right: 50px;
}

.edit-tier > div > span {
    width: 50px;
    height: 50px;
    background-color: lightgray;
    display: block;
    padding: 14px;
    text-align: center;
}

.franchise-fee-message {
    display: flex;
    justify-content: flex-start;
    position: relative;
}
.cap-container {
    /* width: 80%;
    margin-left: 5%; */
    display: flex;
    justify-content: space-between;
}

.cap-spacing {
    margin-bottom: 10px;
}

.cap-spacing .switch {
    padding-top: 12px;
}

.cap-select {
    width: 45%;
}

.franchise-select label {
    /* display: flex; */
    padding-left: 40px;
    
}

.franchise-select {
    position: absolute;
    top: -42px;
    right: 296px;
}

.franchise-container {
    width: 80%;
    margin-left: 5%;
}

.franchise-select  .switch {
    padding-top: 29px;
}

.simple-select {
    /* display: flex;
    justify-content: space-between; */
    width: 45%;

}

.simple-container {
    width: 80%;
    margin-left: 5%;
}

.simple-spacing {
    margin-bottom: 10px;
}

.simple-spacing .switch-ctn {
    margin-top: 12px;

}



.optimality {
	width: 977px;
    background-color:#FBFAFA;
    padding: 25px;
}

.optimality .title {
        color: #828EA1;
        font-family: Helvetica;
        font-size: 18px;
        line-height: 22px;
        margin-top: 0;
}

.optimality-table {
    padding-bottom: 10px;
    width: 100%;
}

.optimality-table > h4 {
    color: #677488;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
}

.optimality-table table {
    width: 100%;
    border-collapse:collapse;
    font-size: 14px;
    table-layout: fixed;
    
}

.optimality-table .table-header {
    background-color: #A4b0c3;
    color: white;
}

.optimality-table .table-header > th {
    height: 25px;
    padding: 0;
    border: 1px solid #a4b0c3;
    text-align: center;
    border-radius: 0;
}

.optimality-table .table-body-row {
    text-align: center;
}

.optimality-table .table-body-row > td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    text-align: right;
    height: 50px;
}

.optimality-table .table-body-row > td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    text-align: center;
}


.optimality-table .recruiting-target {
    width: 100%;
    padding: 15px 25px 15px 25px;
    font-size: 12px;
    max-width: 650px;
}

.optimality-table .noUi-target {
    margin-top: 0;
}

.segment-recruit-ctn {
    padding: 0px 10px;
    width: 85px;
}

.target-message {
    margin-bottom: 5px;
    margin-top: 0px;
    padding-left: 10px;
    font-size: 14px;
    color: #828EA1;
    max-width: 350px;
}

.recruit-input {
    margin-bottom: 2px !important;
}

.target-container {
    display: flex;
    justify-content: space-between;
}

.darken {
    color: #808080;
}

.agent-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: .4em;
}



.optimality {
	width: 977px;
    background-color:#FBFAFA;
    padding: 25px;
}

.optimality-segment {
    margin-top: 20px;
}

.optimality .title {
        color: #828EA1;
        font-family: Helvetica;
        font-size: 18px;
        line-height: 22px;
        margin-top: 0;
}

.optimality-table {
    padding-bottom: 10px;
    width: 100%;
}

.optimality-table > h4 {
    color: #677488;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
}

.optimality-table table {
    width: 100%;
    border-collapse:collapse;
    font-size: 14px;
    table-layout: fixed;
    
}

.optimality-table .table-header {
    background-color: #A4b0c3;
    color: white;
}

.optimality-table .table-header > th {
    height: 25px;
    padding: 0;
    border: 1px solid #a4b0c3;
    text-align: center;
    border-radius: 0;
}

.optimality-table .table-body-row {
    text-align: center;
}

.optimality-table .table-body-row > td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    text-align: right;
    height: 50px;
}

.optimality-table .table-body-row > td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    text-align: center;
}

.optimality-table .table-body-row > td:first-of-type {
    padding-left: 8px;
    text-align: left;
}


.optimality-table .recruiting-target {
    width: 100%;
    padding: 15px 25px 15px 25px;
    font-size: 12px;
    max-width: 650px;
}

.optimality-table .noUi-target {
    margin-top: 0;
}

.seg-button-text {
    color: #E7EDF6;
    letter-spacing: 0px;
    font-size: 20px;
    font-weight: 500;
    padding: 0px 10px;
    margin: 0 25px;
    text-align: center;
    background-color: #828EA1;
    border-radius: 3px;
  }
  
  .seg-button-text:hover {
    background-color: rgb(166, 177, 194);
  }
  
  .seg-button-text:focus {
    background-color: rgb(106, 112, 122);
  }

.optimality-message {
    margin: 20px;
    font-size: 14px;
    color: #828EA1;
}
.subtitle {
    color: #828EA1;
    font-family: Helvetica;
    font-size: 18px;
    line-height: 22px;
}

.agent-text {
    margin: 1px;
}

.agent-list {
    border-bottom: 1px solid lightgrey;
    margin: 10px 0;
    padding: 10px;
}

.agent-full-name {
    font-size: 1.2em;
    font-weight: 600;
}
.header-container {
    height: 45px;
    padding-top: 10px;
    margin: 0 auto;
    width: 1024px;
    background-color: #F6F8FC;
    box-shadow: 0 1px 4px 3px rgba(103,116,136,0.09);
}

.global-title {
   margin: 0px 0 0 20px;
   cursor: pointer;
}


.noUi-target {
    margin-top: 15px;
}

.noUi-horizontal {
    height: 9px !important;
}

.noUi-horizontal .noUi-handle {
    height: 10px !important;
    width: 20px;
        top: -2px !important;
    background-color: #828EA1;
    box-shadow: none;
}

.noUi-tooltip {
    top: -7px;
    height: 23px;
    font-size: 12px;
    color: white !important;
    background-color: #828EA1 !important;
    padding: 2px 10px !important;
}

.slider-container {
    margin-left: 35px;
    width: 100%;
    font-size: 12px;
    max-width: 650px;
}

.slider-container-edit {
    width: 100%;
    font-size: 12px;
    max-width: 650px;
}

.noUi-handle:before, .noUi-handle:after {
    display: none !important;
}


html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    left: -17px !important;
    right: 0 !important;
}
