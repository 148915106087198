.lever {
    margin-left: 0;
    margin-right: 8px;
}

.switch-label {
    margin-right: 16px;
}

.structure-switch {
    padding-top: 30px;
    padding-left: 33px;
}

.structure-switch .lever {
    background-color: lightblue !important;
}

.pc-mid .switch label input[type=checkbox]:checked+.lever:after {
    background-color: #FFFFFF;
}

.pc-mid .switch label .lever:after {
    background-color: #FFFFFF;

}


.pc-mid .switch label .lever:before {
    background-color: #FFFFFF;
}
