.edit-plan-container {
    width: 975px;
    border: 1px solid rgba(164,176,195,0.3);
    background-color: #FCFDFE;
    box-shadow: 0 2px 4px 0 rgba(75,86,104,0.29);
}

.edit-plan-text {
height: 19px;
color: #828EA1;
font-family: "Helvetica Neue";
font-size: 16px;
font-weight: 500;
line-height: 19px;
width: 100%;
padding-top: 40px;
text-align: center;
cursor: pointer;
}

.edit-plan .pc-top > .edit-name {
    width: 300px;;
}

.edit-plan .edit-plan-title {
    color: #828EA1;
    font-family: Helvetica;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 0;
}

.edit-plan .pc-top {
padding: 35px 35px 3px 35px;
border-bottom: none;
position: relative;
}

.edit-plan .pc-mid {
margin: 0 35px;
padding: 21px 0px 10px 0;
border-bottom: 0.5px solid #A4B0C3;
}

.edit-plan .pc-bottom {
margin: 0 35px;
padding: 21px 0px 10px 0;
}

.edit-plan .pc-text-message {
    margin: 0;
height: 18px;
width: 455px;
color: #828EA1;
font-family: "Helvetica Neue";
font-size: 16px;
line-height: 18px;
}

.edit-plan .btn-container {
    display: flex;
    justify-content: center;

}

.edit-plan .edit-button-text {
color: #E7EDF6;
letter-spacing: 0px;
font-family: "Helvetica Neue";
font-size: 14px;
font-weight: 500;
line-height: 17px;
text-align: center;
background-color: #828EA1;
height: 40px;	
border-radius: 3px;

margin: 10px 0px;
}

.edit-plan .edit-button-text:hover {
background-color: rgb(166, 177, 194);
}

.edit-plan .edit-button-text:focus {
background-color: rgb(106, 112, 122);
}


.edit-plan .flex-row {
display: flex;
}


.edit-plan .close-icon {
color:  #828EA1;
position: absolute;
right: 10px;
top: 10px;
cursor: pointer;
z-index: 100;
}



.switch-label {
    color: #828EA1;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: right;
}


.edit-tier{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.edit-tier .tier-table {
    width: 100%;
    margin-right: 0;
}

.edit-tier .tier-table > table {
    table-layout: fixed;
}

.tier-sliders .simple-select {
    width: 100% !important;
}

.tier-sliders .slider-container-edit {
    max-width: 100% !important;
}

.edit-tier > div {
    display: flex;
    margin-top: 20px;
    margin-right: 50px;
}

.edit-tier > div > span {
    width: 50px;
    height: 50px;
    background-color: lightgray;
    display: block;
    padding: 14px;
    text-align: center;
}

.franchise-fee-message {
    display: flex;
    justify-content: flex-start;
    position: relative;
}