.toast-message {
    color: #ffffff !important;
    background-color: #828EA1 !important;
    position: relative;
}

.fix-loader {
    transform: scale(.4) translate(50px, -5px);
    position: absolute;
    top: 3px;
    left: 190px;

}

.optimizing {
    margin-left: 10px;
    font-size: 20px;
}