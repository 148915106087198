.tier-table {
    padding-bottom: 10px;
}

.tier-table table {
    width: 100%;
    border-collapse:collapse;
}

.tier-table .table-header {
    background-color: #A4b0c3;
    color: white;
}

.tier-table .table-header > th {
    height: 25px;
    padding: 0;
    border: 1px solid #a4b0c3;
    text-align: center;
    border-radius: 0;
}

.tier-table .table-body-row {
    text-align: center;
}

.tier-table .table-body-row > td {
    border: 1px solid #d2d8e2;
    color: #677488;
    padding: 0 3px 0 0;
    text-align: right;

}